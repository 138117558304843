import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Franklin({ styles, ...props }) {
  const Elements = useComponents()

  const background_color = styles.forElement("background").backgroundColor
  const checkboxes_color = styles.forElement("field").color
  const border_radius = styles.forElement("field").borderRadius

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div style={styles.forElement("bg_top")} className="bg-top" />

      <div
        className="formkit-container"
        style={{ backgroundColor: background_color }}
      >
        <div
          className="formkit-background-image"
          style={styles.forElement("background")}
        />
        <div className="formkit-main">
          <Elements.Content
            className="formkit-font-serif formkit-name"
            name="name"
            defaults={{
              content: "John Smith"
            }}
          />
          <div>
            <Elements.Heading
              className="formkit-font-serif formkit-heading-primary"
              name="header"
              defaults={{
                content: "Catchy headline goes here."
              }}
            />
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "Provide some more detail in this content area. Vestibulum ut ornare lobortis fermentum a consectetur etiam adipiscing litora consectetur dis lorem parturient himenaeos at ridiculus duis at a ad ridiculus parturient ipsum enim lorem scelerisque."
                }}
              />
            </Elements.Region>
          </div>
        </div>

        <div className="formkit-card" style={styles.forElement("card")}>
          <Elements.Heading
            className="formkit-font-serif formkit-heading-card"
            name="form_header"
            defaults={{ content: "Sign up to my weekly newsletter" }}
          />
          <Elements.Region className="formkit-card-content" name="form_content">
            <Elements.Content
              defaults={{
                content:
                  "Do not miss your chance. Fill out the form below and sign up today."
              }}
            />
          </Elements.Region>
          <Elements.Form>
            <Elements.Errors />
            <Elements.CustomFields
              style={{
                color: checkboxes_color,
                borderRadius: border_radius
              }}
            >
              <Elements.AddFieldButton />
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Sign up today" }}
              />
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content: "We respect your privacy. Unsubscribe at any time."
            }}
          />
        </div>
      </div>
      <Elements.BuiltWith background="page" />
    </Elements.LandingPage>
  )
}

Franklin.style = "landing_page"
Franklin.categories = ["Event", "Newsletter", "Profile", "Webinar"]
Franklin.thumbnail = ""
Franklin.preview = "https://demo.ck.page/franklin"
Franklin.fields = [
  {
    name: "first_name",
    label: "Your name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Franklin, { name: "Franklin" })
